import React, { useState, useEffect } from 'react';
import { Sword, Shield, Crown, Scroll } from 'lucide-react';
import Button from './ui/button';
import { Card, CardContent } from './ui/card';

const LandingPage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const img = new Image();
    img.src = `${process.env.PUBLIC_URL}/arka_plan.png`;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  const features = [
    {
      icon: <Sword className="w-8 h-8 text-amber-600" />,
      title: "Epik Savaşlar",
      description: "Yüzlerce askeri yönetin, stratejik savaşlar planlayın ve zafer kazanın."
    },
    {
      icon: <Crown className="w-8 h-8 text-amber-600" />,
      title: "Krallığınızı Yükseltin",
      description: "Topraklarınızı genişletin, ekonominizi güçlendirin ve hanedanlığınızı kurun."
    },
    {
      icon: <Shield className="w-8 h-8 text-amber-600" />,
      title: "Kaleler İnşa Edin",
      description: "Muhteşem kaleler inşa edin, savunmanızı güçlendirin ve düşmanlarınıza meydan okuyun."
    },
    {
      icon: <Scroll className="w-8 h-8 text-amber-600" />,
      title: "Zengin Hikaye",
      description: "Orta çağın büyüleyici dünyasında kendi destanınızı yazın."
    }
  ];

  const backgroundStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/arka_plan.png)`,
    opacity: imageLoaded ? 0.4 : 0
  };

  return (
    <div className="min-h-screen bg-slate-900 text-white">
      {/* Hero Section */}
      <div className={`relative h-screen flex items-center justify-center transition-all duration-1000 
        ${isVisible ? 'opacity-100' : 'opacity-0'}
        ${imageLoaded ? '' : 'bg-slate-800'}`}>
        <div 
          className="absolute inset-0 bg-cover bg-center bg-no-repeat transition-opacity duration-500"
          style={backgroundStyle}
        />
        <div className="relative z-10 text-center px-4">
          <h1 className="text-6xl font-bold mb-6 text-amber-500 drop-shadow-lg">
            Feudalverse
          </h1>
          <p className="text-xl mb-8 max-w-2xl mx-auto drop-shadow-lg bg-slate-900/50 p-4 rounded-lg">
            Orta çağın destansı dünyasına adım atın. Kendi krallığınızı kurun, 
            ordularınızı yönetin ve tarihe adınızı yazdırın.
          </p>
          <div className="space-x-4">
            <Button className="bg-amber-600 hover:bg-amber-700 text-white px-8 py-6 text-lg rounded-full shadow-lg transform hover:scale-105 transition-transform">
              Şimdi Oyna
            </Button>
            <Button className="bg-slate-800 hover:bg-slate-700 text-white px-8 py-6 text-lg rounded-full shadow-lg transform hover:scale-105 transition-transform">
              Daha Fazla Bilgi
            </Button>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-4xl font-bold text-center mb-12 text-amber-500">Oyun Özellikleri</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <Card 
              key={index} 
              className="bg-slate-800 border-amber-600 border hover:transform hover:scale-105 transition-all duration-300"
            >
              <CardContent className="p-6">
                <div className="flex flex-col items-center text-center">
                  <div className="mb-4 transform hover:rotate-12 transition-transform">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-bold mb-2 text-amber-500">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-slate-800 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6 text-amber-500">Maceraya Hazır mısınız?</h2>
          <p className="mb-8 text-lg max-w-2xl mx-auto">
            Feudalverse'ün büyüleyici dünyasında yerinizi alın ve kendi 
            hanedanlığınızın efsanesini yazmaya başlayın.
          </p>
          <Button className="bg-amber-600 hover:bg-amber-700 text-white px-6 py-4 text-lg rounded-full shadow-lg transform hover:scale-105 transition-transform">
            Ücretsiz Başla
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;